<template>
  <div class="public">
    <div class="container HomeSupervise">
      <!--  饼图 -->
      <div class="idiot-one">
        <div class="box" v-for="item in forCodeData" :key="item.type">
          <div class="box-left">
            <div
              class="box-leftchild"
              v-for="(itemval, ind) in item.seriesData"
              :key="ind"
            >
              {{ itemval.name }}
              <div>{{ itemval.value }}</div>
              {{ itemval.valcom }}
            </div>
          </div>
          <div class="box-right">
            <Pieadvance
              :chartData="{
                seriesData: item.seriesData,
              }"
            ></Pieadvance>
            <div class="pietitle">
              <div
                class="pietitlechild"
                v-for="(item, ind) in item.seriesData"
                :key="ind"
              >
                <div
                  class="pietitlechildurl"
                  :style="{ background: colors[ind] }"
                ></div>
                <div class="pietitlechildval">
                  {{ item.name }} {{ item.rate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 预付合同签约区域分布情况 地图 -->
      <div class="idiot-two echarts pie-box">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>预付合同区域分布图</span>
          </div>
        </div>

        <div class="map-charts">
          <Baidumap
            v-if="regionType >= 3 && areaName"
            v-loading="showbmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :flag="flag"
            :cityName="cityName"
            :areaName="areaName"
            :data="baidumapOptions"
          />
          <Pmap
            v-else
            v-loading="showmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :cityName="cityName"
            :flag="flag"
            :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 合同签约新增变化趋势 柱状图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>预付合同新增变化趋势</span>
          </div>
          <KcSelect left="0" top="18" @popupVal="popupVal"></KcSelect>
        </div>
        <div class="charts">
          <LineChart
            v-loading="barChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :chartData="barChartData"
            :legendData="legendData"
            :units="unitData"
            :yAxis="['']"
            :yAxisIndex="['0']"
          ></LineChart>
          <!-- <BarChart
            :chartData="barChartData"
            :legendData="legendData"
            :units="unitData"
          ></BarChart> -->
        </div>
        <Tab :optionData="optionData" @popupswitch="popupVal2"></Tab>
      </div>
      <!-- 合同签约累计变化趋势 折线图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>预付合同累计变化趋势</span>
          </div>
          <KcSelect left="0" top="18" @popupVal="popupVal3"></KcSelect>
        </div>
        <div class="charts">
          <LineChart
            v-loading="lineChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :chartData="lineChartData"
            :legendData="legendData2"
            :units="unitData2"
            :yAxis="['']"
            :yAxisIndex="['0']"
          ></LineChart>
        </div>
        <Tab :optionData="optionData" @popupswitch="popupVal4"></Tab>
      </div>
      <!-- 监管资金按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>预付合同区域分布数量统计</span>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
            highlight-current-row
            :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              prop="regionName"
              :key="0"
              label="地区"
              :width="0.18 * $bodyWidth"
              fixed
            >
            </el-table-column>
            <template>
              <el-table-column prop="money" :key="1" label="已签约" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.signNum }}</span
                  ><i
                    :class="
                      scope.row.signNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.signNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.signNumTit > 0
                        ? "+" + scope.row.signNumTit
                        : scope.row.signNumTit < 0
                        ? "" + scope.row.signNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="custodyMoney"
                :key="2"
                label="未签约"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.unSignNum }}</span
                  ><i
                    :class="
                      scope.row.unSignNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.unSignNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.unSignNumTit > 0
                        ? "+" + scope.row.unSignNumTit
                        : scope.row.unSignNumTit < 0
                        ? "" + scope.row.unSignNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column prop="insuranceMoney" label="履约中" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.keepNum }}</span
                  ><i
                    :class="
                      scope.row.keepNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.keepNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.keepNumTit > 0
                        ? "+" + scope.row.keepNumTit
                        : scope.row.keepNumTit < 0
                        ? "" + scope.row.keepNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column prop="insuranceedMoney" label="已履约" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.keepedNum }}</span
                  ><i
                    :class="
                      scope.row.keepedNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.keepedNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.keepedNumTit > 0
                        ? "+" + scope.row.keepedNumTit
                        : scope.row.keepedNumTit < 0
                        ? "" + scope.row.keepedNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                :key="4"
                label="已违约"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.unKeepNum }}</span
                  ><i
                    :class="
                      scope.row.unKeepNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.unKeepNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.unKeepNumTit > 0
                        ? "+" + scope.row.unKeepNumTit
                        : scope.row.unKeepNumTit < 0
                        ? "" + scope.row.unKeepNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 监管资金按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>预付合同领域/行业分布数量统计</span>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryId"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort2"
            :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              prop="industryName"
              :key="0"
              label="领域/行业"
              sortable
              :width="0.32 * $bodyWidth"
              fixed
            >
            </el-table-column>
            <template>
              <el-table-column
                prop="money"
                :key="1"
                label="已签约"
                sortable
                :width="0.21 * $bodyWidth"
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.signNum }}</span
                  ><i
                    :class="
                      scope.row.signNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.signNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.signNumTit > 0
                        ? "+" + scope.row.signNumTit
                        : scope.row.signNumTit < 0
                        ? "" + scope.row.signNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="custodyMoney"
                :key="2"
                :width="0.21 * $bodyWidth"
                label="未签约"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.unSignNum }}</span
                  ><i
                    :class="
                      scope.row.unSignNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.unSignNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.unSignNumTit > 0
                        ? "+" + scope.row.unSignNumTit
                        : scope.row.unSignNumTit < 0
                        ? "" + scope.row.unSignNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                label="履约中"
                :width="0.21 * $bodyWidth"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.keepNum }}</span
                  ><i
                    :class="
                      scope.row.keepNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.keepNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.keepNumTit > 0
                        ? "+" + scope.row.keepNumTit
                        : scope.row.keepNumTit < 0
                        ? "" + scope.row.keepNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceedMoney"
                label="已履约"
                sortable
                :width="0.21 * $bodyWidth"
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.keepedNum }}</span
                  ><i
                    :class="
                      scope.row.keepedNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.keepedNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.keepedNumTit > 0
                        ? "+" + scope.row.keepedNumTit
                        : scope.row.keepedNumTit < 0
                        ? "" + scope.row.keepedNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                :key="4"
                label="已违约"
                :width="0.21 * $bodyWidth"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.unKeepNum }}</span
                  ><i
                    :class="
                      scope.row.unKeepNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.unKeepNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.unKeepNumTit > 0
                        ? "+" + scope.row.unKeepNumTit
                        : scope.row.unKeepNumTit < 0
                        ? "" + scope.row.unKeepNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
              >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line2"; // 四码折线图
import PieSmallChart from "@/components/charts/pieSmall"; //饼图
import BarChart from "@/components/charts/bar";
import Pmap from "@/components/charts/map.vue"; //地图
import Baidumap from "@/components/baidumap/index.vue";
import Pieadvance from "@/components/charts/Pieadvance.vue";
import Tab from "@/components/tab.vue";
// import { Picker, Popup } from "mint-ui";
import {
  contractStatistics,
  regionalContractInfo,
  contractSignTrend,
  safeCodeCumulative,
  contractCityStatistics,
  contractIndustryStatistics,
} from "@/api/homePrepaidcontract";
import { cardType } from "@/api/common";

export default {
  name: "HomeSupervise",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    return {
      barChart1: false,
      lineChart2: false,
      popupVisible1: false,
      colors: ["#feb837", "#4f77e1", "#26fff7"],
      forCodeData: [
        {
          seriesData: [
            {
              name: "已签约",
              value: 0,
              valcom: "份",
            },
            {
              name: "未签约",
              value: 0,
              valcom: "份",
            },
          ],
        },
        {
          seriesData: [
            {
              name: "履约中",
              value: 0,
              valcom: "份",
            },
            {
              name: "已履约",
              value: 0,
              valcom: "份",
            },
            {
              name: "已违约",
              value: 0,
              valcom: "份",
            },
          ],
        },
      ],
      tabPosition2: 0,
      tabPosition3: 0,
      isTowPie: false,
      statisticsData: [],
      cunguan: "",
      baoxian: "",
      barChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "已签约",
            value: [],
          },
          {
            name: "未签约",
            value: [],
          },
        ],
      },
      legendData: ["已签约", "未签约"],
      unitData: ["份", "份"],
      radio1: "1",
      radio2: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],

      optionData: [
        { name: "合同签约情况", haripinType: 0 },
        { name: "合同履约情况", haripinType: 1 },
      ],

      lineChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "已签约",
            data: [],
          },
          {
            name: "未签约",
            data: [],
          },
        ],
      },
      legendData2: ["已签约", "未签约"],
      unitData2: ["份", "份"],
      hairpinType1: "",
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      hairpinType2: "",
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      mapOptions: [],
      baidumapOptions: [],
      showmap: true,
      showbmap: true,
    };
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.contractSignTrend();
    },
    tabPosition2: function (val) {
      this.contractSignTrend();
      this.legendData =
        val == 0 ? ["已签约", "未签约"] : ["履约中", "已履约", "已违约"];
      this.unitData = val == 0 ? ["份", "份"] : ["份", "份", "份"];
    },
    radio2: function (val) {
      this.safeCodeCumulative();
    },
    tabPosition3: function (val) {
      this.safeCodeCumulative();
      this.legendData2 =
        val == 0 ? ["已签约", "未签约"] : ["履约中", "已履约", "已违约"];
      this.unitData2 = val == 0 ? ["份", "份"] : ["份", "份", "份"];
    },

    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  mounted() {
    this.intData();
  },
  methods: {
    selectClick() {
      this.popupVisible1 = true;
    },
    popupVal(val, row) {
      this.radio1 = val;
      // console.log(val);
      // console.log(row);
    },
    popupVal3(val, row) {
      this.radio2 = val;
      // console.log(val);
      // console.log(row);
    },
    popupVal2(val) {
      this.tabPosition2 = val.haripinType;
      console.log("val=====", val);
    },
    popupVal4(val) {
      this.tabPosition3 = val.haripinType;
    },

    onValuesChange1(picker, values) {
      this.message = values[0];
    },
    sure1() {
      this.saleType = this.message;
      this.popupVisible1 = false;
    },

    intData() {
      this.contractStatistics();
      this.getMapOptionData();
      this.contractSignTrend();
      this.safeCodeCumulative();
      this.contractCityStatistics();
      this.contractIndustryStatistics();
    },

    contractStatistics() {
      let _this = this;
      contractStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        console.log(res);
        this.forCodeData[0].seriesData[0].value = res.data.signNum;
        this.forCodeData[0].seriesData[1].value = res.data.unSignNum;
        this.forCodeData[1].seriesData[0].value = res.data.keepContractNum;
        this.forCodeData[1].seriesData[1].value = res.data.keepedContractNum;
        this.forCodeData[1].seriesData[2].value = res.data.unKeepContractNum;
        this.forCodeData[0].seriesData[0].rate = res.data.signRate;
        this.forCodeData[0].seriesData[1].rate = res.data.unSignRate;
        this.forCodeData[1].seriesData[0].rate = res.data.keepContractRate;
        this.forCodeData[1].seriesData[1].rate = res.data.keepedContractRate;
        this.forCodeData[1].seriesData[2].rate = res.data.unKeepContractRate;
      });
    },
    contractSignTrend() {
      this.barChartData = {
        xAxisData: [],
        seriesData: [
          {
            name:
              this.tabPosition2 == 0
                ? "已签约"
                : this.tabPosition2 == 1
                ? "履约中"
                : "",
            data: [],
          },
          {
            name:
              this.tabPosition2 == 0
                ? "未签约"
                : this.tabPosition2 == 1
                ? "已履约"
                : "",
            data: [],
          },
          {
            name:
              this.tabPosition2 == 0
                ? ""
                : this.tabPosition2 == 1
                ? "已违约"
                : "",
            data: [],
          },
        ],
      };
      this.barChart1 = true;
      contractSignTrend({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
      }).then((res) => {
        //console.log('监管资金新增变化趋势',res)
        this.barChart1 = false;
        if (res.data != null) {
          this.barChartData = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name:
                  this.tabPosition2 == 0
                    ? "已签约"
                    : this.tabPosition2 == 1
                    ? "履约中"
                    : "",
                data:
                  this.tabPosition2 == 0
                    ? res.data.signTrend
                    : this.tabPosition2 == 1
                    ? res.data.keepSignTrend
                    : [],
              },
              {
                name:
                  this.tabPosition2 == 0
                    ? "未签约"
                    : this.tabPosition2 == 1
                    ? "已履约"
                    : "",
                data:
                  this.tabPosition2 == 0
                    ? res.data.unSignTrend
                    : this.tabPosition2 == 1
                    ? res.data.unKeepSignTrend
                    : [],
              },
              {
                name:
                  this.tabPosition2 == 0
                    ? ""
                    : this.tabPosition2 == 1
                    ? "已违约"
                    : "",
                data:
                  this.tabPosition2 == 0
                    ? []
                    : this.tabPosition2 == 1
                    ? res.data.unKeepSignTrend
                    : [],
              },
            ],
          };
        }
      });
    },
    safeCodeCumulative() {
      this.lineChartData = {
        xAxisData: [],
        seriesData: [
          {
            name:
              this.tabPosition3 == 0
                ? "已签约"
                : this.tabPosition3 == 1
                ? "履约中"
                : "",
            data: [],
          },
          {
            name:
              this.tabPosition3 == 0
                ? "未签约"
                : this.tabPosition3 == 1
                ? "已履约"
                : "",
            data: [],
          },
          {
            name:
              this.tabPosition3 == 0
                ? ""
                : this.tabPosition3 == 1
                ? "已违约"
                : "",
            data: [],
          },
        ],
      };
      this.lineChart2 = true;
      safeCodeCumulative({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
      }).then((res) => {
        this.lineChart2 = false;
        //console.log('监管资金累计变化趋势',res)
        if (res.data != null) {
          this.lineChartData = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name:
                  this.tabPosition3 == 0
                    ? "已签约"
                    : this.tabPosition3 == 1
                    ? "履约中"
                    : "",
                data:
                  this.tabPosition3 == 0
                    ? res.data.signTrend
                    : this.tabPosition3 == 1
                    ? res.data.keepSignTrend
                    : [],
              },
              {
                name:
                  this.tabPosition3 == 0
                    ? "未签约"
                    : this.tabPosition3 == 1
                    ? "已履约"
                    : "",
                data:
                  this.tabPosition3 == 0
                    ? res.data.unSignTrend
                    : this.tabPosition3 == 1
                    ? res.data.unKeepSignTrend
                    : [],
              },
              {
                name:
                  this.tabPosition3 == 0
                    ? ""
                    : this.tabPosition3 == 1
                    ? "已违约"
                    : "",
                data:
                  this.tabPosition3 == 0
                    ? []
                    : this.tabPosition3 == 1
                    ? res.data.unKeepSignTrend
                    : [],
              },
            ],
          };
        }
      });
    },
    contractCityStatistics() {
      let _this = this;
      this.loading1 = true;
      contractCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按城市/地区统计',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        this.loading1 = false;
        this.tableDataAll = [];
        this.$nextTick(() => {
          this.tableData1 = res.data;
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      contractCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        console.log(res, 12345);
        //console.log('预付码按城市/地区统计-二级',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        resolve(res.data);
      });
    },
    contractIndustryStatistics() {
      this.loading2 = true;
      contractIndustryStatistics({
        hairpinType: this.hairpinType2,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        if (res.code !== 500 && res.data && res.data.length) {
          //console.log('预付码按领域/行业统计',res)
          res.data.forEach((item) => {
            item.hasChildren = true;
          });
          this.loading2 = false;
          this.tableDataAll2 = [];
          this.$nextTick(() => {
            this.tableData2 = res.data;
            this.tableDataAll2 = this.tableData2.slice(0, 10);
            this.showAllData2();
          });
        }
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      contractIndustryStatistics({
        hairpinType: this.hairpinType2,
        industryId: tree.industryId,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按领域/行业统计-二级',res)
        resolve(res.data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },

    getMapOptionData() {
      this.showbmap = true;
      this.showmap = true;
      regionalContractInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          //console.log('商户区域分布情况:',res)
          //this.mapOptions = res.data;
          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              value: item.num,
              signNum: item.signNum,
              unSignNum: item.unSignNum,
              keepContractNum: item.keepContractNum,
              keepedContractNum: item.keepedContractNum,
              unKeepContractNum: item.unKeepContractNum,
            });
          });
          this.mapOptions = data;
          this.showmap = false;

          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
            this.showbmap = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    LineChart,
    BarChart,
    PieSmallChart,
    Pieadvance,
    Pmap,
    Tab,
    Baidumap,
    // Picker,
    // Popup
  },
};
</script>

<style lang="scss" scoped>
.HomeSupervise {
  // 四色码
  .idiot-one {
    margin-bottom: 30px;
    background: white;
    .box {
      width: 100%;
      text-align: center;
      display: flex;
      .box-left {
        width: 40%;
        height: 360px;
        .box-leftchild {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          margin-left: 85px;
          // justify-content: center;
          font-size: 30px;
          color: #232a24;
          & > div {
            font-size: 30px;
            color: #232a24;
            margin: 0 5px;
          }
        }

        .box-leftchild:nth-child(1) {
          margin-top: 70px;
          margin-bottom: 20px;
        }
      }

      .box-right {
        width: 60%;
        height: 360px;
        position: relative;
        .pietitle {
          position: absolute;
          bottom: 5px;
          right: 0;
          width: 100%;
          .pietitlechild {
            height: 45px;
            display: flex;
            align-items: center;
            padding-left: 150px;
            // justify-content: center;
            font-size: 21px;
            color: #312d37;
            .pietitlechildurl {
              width: 12px;
              height: 12px;
              margin-right: 10px;
            }
          }
        }
      }

      &:nth-child(2) {
        .box-header span {
          color: #18bbff;
        }
      }
    }

    .box:nth-child(1) {
      border-bottom: 1px dashed #eeeeee;
    }
  }

  .selectbox {
    position: absolute;
    top: 110px;
    left: 40px;
    border: 2px solid #6c6c6c;
    border-radius: 5px;
    color: #232a24;
    padding: 10px 19px;
    z-index: 9;
    display: flex;
    .selectTitle {
      font-size: 20px;
      margin-right: 18px;
      line-height: 20px;
    }
    .selectTitleicon {
      font-size: 30px;
      line-height: 20px;
      transform: rotate(180deg);
      transition: transform 1s;
    }
  }

  .picker-toolbar-title {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    line-height: 100%;
    font-size: 0.7rem;
    .usi-btn-cancel,
    .usi-btn-sure {
      color: #ff6600;
    }
  }

  .pie-box {
    margin-bottom: 30px;

    .echarts-box {
      position: relative;

      .echarts-tit {
        .txt {
          margin-left: 30px;
          line-height: 40px;

          i {
            font-weight: bold;
          }
        }
      }
    }

    .pie-chart-box {
      .txt {
        width: 50%;
        float: left;
        box-sizing: border-box;
        text-align: center;
        margin-top: 50px;

        span:nth-child(1) {
          display: block;
          font-size: 18px;
        }

        span:nth-child(2) {
          display: block;
          font-size: 30px;
          color: #5bd5f7;

          i {
            font-size: 18px;
            margin-left: 10px;
          }
        }

        &:nth-child(2) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              transparent,
              transparent,
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            0 30 30 30;

          span:nth-child(2) {
            color: #208dfd;
          }
        }
      }

      .pie-charts {
        float: left;
        width: 50%;
        height: 550px;
        box-sizing: border-box;
        background: url("../assets/imgs/pie-bg2.png") no-repeat center center;
        background-size: 55%;

        &:nth-child(3) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            30 30;
        }
      }
    }
  }

  .tab-select-btn {
    position: absolute;
    top: 24px;
    right: 0;

    &.right-size {
      right: 120px;
    }
  }
}
</style>
